import NextLink from 'components/foundation/non-sitecore/NextLink';
import { NavigationLink } from '../global-navigation/shop/navigation-props';
import { UtilityNavigationProps } from './utility-navigation-props';

const ContactSalesNavigation = (props: UtilityNavigationProps): JSX.Element => {
  const contactSalesItem = props.fields?.data?.datasource?.children?.results?.find(
    (i) => i.name === 'Contact Sales'
  ) as NavigationLink;

  return (
    <NextLink
      field={contactSalesItem?.link?.jsonValue}
      className="group hover:text-accent-cool-light rounded text-avidgreen relative active:text-accent-cool-lighter leading-none flex flex-row flex-wrap"
      hideText={true}
    >
      <>
        <span
          className={`w-6 h-6 inline-block
            bg-[url('https://cdn.avid.com/avidcom/images/shared/icons/contact-icon.svg')]
            bg-[length:18px_18px] bg-no-repeat bg-center`}
        ></span>
        <span className="group-hover:underline font-avidwalsheimbold group-active:underline lg:not-sr-only sr-only break-normal font-bold leading-normal lg:ml-1">
          {contactSalesItem ? contactSalesItem.link?.jsonValue?.value?.text : 'Contact Sales'}
        </span>
      </>
    </NextLink>
  );
};

export default ContactSalesNavigation;
